<template lang="pug">
.card
	template(v-if="product")
		.card__title.flex
			.card__title-text(v-if="product.name") {{ product.name }}
			Link.card__link(
				v-if="product.show_url"
				:href="product.productUrl"
				target="_blank"
				native
				unstyled
			)
				OpenInNew(:size="16")
		.card__content.flex.justify-between
			.card__image
				.card__image-title Карточка клиента
				img.card__image-img(:src="product.harvestedImage")
			.card__image
				.card__image-title Карточка из BQ
				img.card__image-img(:src="product.trustedImage")
		.card__info
			.card__info-item.flex
				.card__info-sub Store:
				.card__info-text(v-if="product.storeName") {{ product.storeName }}
			.card__info-item.flex
				.card__info-sub Category:
				.card__info-text(v-if="product.categoryName") {{ product.categoryName }}
			.card__info-item.flex
				.card__info-sub Brand:
				.card__info-text(v-if="product.brandName") {{ product.brandName }}
			.card__info-item.flex
				.card__info-sub RPC:
				.card__info-text {{ product.rpc }}
			.card__info-item.flex
				.card__info-sub UPC:
				.card__info-text {{ product.upc }}
			.card__info-item.flex
				.card__info-sub Result:
				.card__info-text.result(:class="resultClass") {{ product.type }}
</template>

<script>
import Link from '@/components/Link/Link.vue'
import OpenInNew from 'vue-material-design-icons/OpenInNew.vue'

export default {
	components: {
		Link,
		OpenInNew,
	},
	props: {
		product: {
			type: Object,
			default: null,
		},
	},
	computed: {
		resultClass() {
			return this.product.type.toLowerCase()
		},
	},
}
</script>

<style lang="scss" scoped>
.card {
	border: 1px solid color(gray-300);
	&__title {
		height: 29px;
		padding: 5px 10px;
		background-color: color(gray-100);
		border-bottom: 1px solid color(gray-300);

		&-text {
			max-width: calc(100% - 30px);
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}
	}
	&__link {
		display: inline-block;
		margin-top: 2px;
		margin-left: 8px;
		color: color(blue-bright);
	}
	&__content {
		border: 2px solid transparent;

		&:hover {
			border: 2px solid color(blue-bright);
		}
	}
	&__image {
		width: 50%;
		padding: 5px;
		border-bottom: 1px solid color(gray-300);

		&:first-of-type {
			border-right: 1px solid color(gray-300);
		}

		&-title {
			text-align: center;
			color: color(blue-bright);
			font-size: 14px;
		}
		&-img {
			max-width: 150px;
			max-height: 150px;
			margin: 0 auto;
		}
	}
	&__info {
		padding: 5px 10px;

		&-item {
			margin-top: 2px;

			&:first-of-type {
				margin-top: 0;
			}
		}
		&-sub {
			width: 30%;
			font-weight: 700;
		}
	}
}
.result {
	padding: 2px 6px;
	color: white;
	border-radius: 6px;
}
.pass {
	background-color: #4CAF50;
}
.incorrect {
	background-color: #E53914;
}
.partial {
	background-color: #FEB237;
}
.noReference {
	background-color: #FD5A31;
}
.unreadable {
	background-color: #FE6E49;
}
</style>
